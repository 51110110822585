import { Box, Grid } from "@mui/material";
import { Form, Formik } from "formik";
import { useRef } from "react";
import { useDispatch } from "react-redux";
import CTypography from "src/components/CTypography";
import FormikControl from "src/components/formik/FormikControl";
import { approveRejectTimesheet } from "src/slices/timesheet/thunks";
import { setDialog } from "src/slices/uiSettingsSlice";
import { AppDispatch } from "src/store";

export const DeclineTimesheet = ({ dialogName, declineTimesheetData }) => {
  const dispatch = useDispatch<AppDispatch>();
  const formikRef = useRef(null);

  // Form Inital Values
  const formInitialValue = {
    comment: "",
  };

  return (
    <Box sx={{ width: 1 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Formik
            initialValues={formInitialValue}
            innerRef={formikRef}
            onSubmit={(values) => {
              dispatch(
                approveRejectTimesheet({
                  ...declineTimesheetData,
                  comment: values.comment,
                })
              ).then(() => {
                dispatch(setDialog({ open: false, dialogName: "DeclineTimesheet" }));
              });
            }}
          >
            {({ touched, errors, getFieldProps }) => (
              <Form id={dialogName}>
                <Grid container spacing={1} pb={3}>
                  <Grid item xs={12}>
                    <CTypography
                      sx={{
                        color: "#1E1E1E",
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "normal",
                      }}
                    >
                      Please enter reason for Decline
                    </CTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <FormikControl
                      required
                      control="Textarea"
                      name="comment"
                      label={"Comment"}
                      multiline
                      rows={4}
                      error={Boolean(touched.comment && errors.comment)}
                      helperText={touched.comment && errors.comment}
                      {...getFieldProps("comment")}
                    />
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Box>
  );
};

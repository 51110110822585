import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import { useLocation, useNavigate } from "react-router-dom";
import { RoutePaths } from "src/constants";
import { useSelector } from "react-redux";
import { selectUser } from "src/slices/auth/selectors";
import { AppUser } from "src/types/auth/auth";

const navItems = [
  {
    label: "Timesheet",
    link: RoutePaths.TIMESHEET_LISTING,
    accessibleRole: ["All"],
    icon: <DashboardIcon />,
  },
  {
    label: "Approval Timesheet",
    link: RoutePaths.APPROVAL_TIMESHEET,
    accessibleRole: ["Project Manager", "Admin"],
    icon: <LocalAtmIcon />,
  },
  {
    label: "Customers",
    link: RoutePaths.CUSTOMER_LISTING,
    accessibleRole: ["Project Manager", "Admin"],
    icon: <LocalAtmIcon />,
  },
  {
    label: "Employees",
    link: RoutePaths.EMPLOYEE_LISTING,
    accessibleRole: ["Project Manager", "Admin"],
    icon: <LocalAtmIcon />,
  },
  {
    label: "Reports",
    link: RoutePaths.REPORTS,
    accessibleRole: ["Project Manager", "Admin"],
    icon: <LocalAtmIcon />,
  },
  {
    label: "Projects",
    link: RoutePaths.PROJECTS,
    accessibleRole: ["Project Manager", "Admin"],
    icon: <LocalAtmIcon />,
  },
  {
    label: "Employee Project Assignment",
    link: RoutePaths.EMP_PROJECT_LISTING,
    accessibleRole: ["Project Manager", "Admin"],
    icon: <LocalAtmIcon />,
  },
];

export const DrawerNavigationList = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const user: AppUser = useSelector(selectUser);

  return (
    <React.Fragment>
      {navItems.map((item) => (
        <>
          {item.accessibleRole.includes('All') || item.accessibleRole.includes(user?.employeeInfo?.employeeRole) ? (
            <ListItemButton
              key={item.label}
              selected={pathname === item.link}
              onClick={() => {
                navigate(item.link);
              }}
              sx={{
                textWrap:'wrap',
                "&.Mui-selected": {
                  color: "white",
                  backgroundColor: "#F15C3B",
                  ":hover":{
                    backgroundColor:"#f28269"
                  }
                },
              }}
            >
              <ListItemIcon
                sx={{ color: pathname === item.link ? "white" : "" }}
              >
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary={item.label} />
            </ListItemButton>
          ) : null}
        </>
      ))}
      {/* Timesheet Listing  */}
    </React.Fragment>
  );
};
